<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="titleBar">
            <img class="logo" src="../assets/logo.png" width="120" height="48" />
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div v-if="loginUserStore.loginUser.id">
        <a-dropdown trigger="hover">
          <a-menu slot="overlay">
            <a-menu-item>
              <a class="ant-dropdown-link" @click.prevent>
                {{ loginUserStore.loginUser.userName ?? "无名之辈" }} <a-icon type="down" />
              </a>
            </a-menu-item>
          </a-menu>
          <template #content>
            <a-doption  key="logout" @click="handleLogout">
              退出登录
            </a-doption>
          </template>
        </a-dropdown>
      </div>
      <div v-else>
        <a-button type="primary" href="/user/login">登录</a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useLoginUserStore, userLogout } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import {Modal} from "@arco-design/web-vue";

const loginUserStore = useLoginUserStore();

const logoutUserStore = userLogout;

const router = useRouter();
// 当前选中的菜单项
const selectedKeys = ref(["/"]);
// 路由跳转时，自动更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 展示在菜单栏的路由数组
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 根据权限过滤菜单
    if (!checkAccess(loginUserStore.loginUser, item.meta?.access as string)) {
      return false;
    }
    return true;
  });
});

// 点击菜单跳转到对应页面
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

// 退出登录的方法
const handleLogout = () => {
  Modal.confirm({
    title: '确认退出登录吗？',
    onOk: () => {
      // 执行退出登录的逻辑，例如清除用户信息、跳转到登录页面等
      logoutUserStore();
      router.push('/user/login');
    },
    onCancel: () => {
      // 取消退出登录
    },
    content: ''
  });
};
</script>

<style scoped>
#globalHeader {
}

.titleBar {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 16px;
  color: black;
}

.logo {
  height: 48px;
}
</style>
