import { defineStore } from "pinia";
import { ref } from "vue";
import { getLoginUserUsingGet, userLogoutUsingPost } from "@/api/userController";
import ACCESS_ENUM from "@/access/accessEnum";

/**
 * 登录用户信息全局状态
 */
export const useLoginUserStore = defineStore("loginUser", () => {
  const loginUser = ref<API.LoginUserVO>({
    userName: "未登录",
  });

  function setLoginUser(newLoginUser: API.LoginUserVO) {
    loginUser.value = newLoginUser;
  }

  async function fetchLoginUser() {
    const res = await getLoginUserUsingGet();
    if (res.data.code === 0 && res.data.data) {
      loginUser.value = res.data.data;
    } else {
      loginUser.value = { userRole: ACCESS_ENUM.NOT_LOGIN };
    }
  }

  return { loginUser, setLoginUser, fetchLoginUser};
});

/**
 * 退出登录
 */
export async function userLogout() {
  const res = await userLogoutUsingPost();
  if (res.data.code === 0) {
    useLoginUserStore().setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
  }
}

