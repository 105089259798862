<template>
  <a-button type="outline" @click="handleClick">AI 生成题目</a-button>
  <a-drawer
    :width="340"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    unmountOnClose
  >
    <template #title> AI生成题目 </template>
    <div>
      <a-form
        :model="form"
        :style="{}"
        label-align="left"
        auto-label-width
        @submit="handleSubmit"
      >
        <a-form-item label="应用id"> {{ props.appId }} </a-form-item>
        <a-form-item field="questionCount" label="题目数量">
          <a-input
            number
            min="1"
            max="20"
            v-model="form.questionCount"
            placeholder="请输入题目数量"
          />
        </a-form-item>
        <a-form-item field="optionCount" label="选项数量">
          <a-input
            number
            min="2"
            max="6"
            v-model="form.optionCount"
            placeholder="请输入选项数量"
          />
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button
              :loading="submitting"
              type="primary"
              html-type="submit"
              style="width: 120px"
            >
              {{ submitting ? "生成中..." : "一键生成" }}
            </a-button>
            <a-button
              :loading="SSEsubmitting"
              style="width: 120px"
              @click="handleSSEsubmit"
            >
              {{ SSEsubmitting ? "生成中..." : "实时生成" }}
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
  </a-drawer>
</template>

<script setup lang="ts">
import { defineProps, reactive, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/api/appController";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/app";
import { aiGenerateQuestionUsingPost } from "@/api/questionController";

interface Props {
  appId: string;
  onSuccess?: (result: API.AIGenerateQuestionRequest[]) => void;
  onSSESuccess?: (result: API.AIGenerateQuestionRequest) => void;
  onSSEStart?: (event: any) => void;
  onSSEEnd?: (event: any) => void;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});

const form = reactive({
  optionCount: 2,
  questionCount: 4,
} as API.AIGenerateQuestionRequest);

const visible = ref(false);
const submitting = ref(false);
const SSEsubmitting = ref(false);

const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId) {
    return;
  }
  submitting.value = true;
  const res = await aiGenerateQuestionUsingPost({
    appId: props.appId as any,
    ...form,
  });
  if (res.data.code === 0 && res.data.data.length > 0) {
    message.success("生成成功");
    if (props.onSuccess) {
      props.onSuccess(res.data.data);
    } else {
      message.success("请在左侧菜单中选择应用查看生成的题目");
    }
    // 成功则关闭抽屉
    handleCancel();
  } else {
    message.error("生成失败");
  }
  submitting.value = false;
  //
};
/**
 * SSE提交 (流式生成)
 */
const handleSSEsubmit = async () => {
  if (!props.appId) {
    return;
  }
  SSEsubmitting.value = true;
  // 创建SSE请求
  const eventSource = new EventSource(
    // TODO 手动填写完整的后端地址
    `http://localhost:8101/api/question/ai_generate/sse?appId=${props.appId}&optionCount=${form.optionCount}&questionCount=${form.questionCount}`
  );
  let firstMessage = true;
  // 接收消息
  eventSource.onmessage = function (event) {
    if (firstMessage) {
      props.onSSEStart?.(event);
      handleCancel();
      firstMessage = false;
    }
    props.onSSESuccess?.(JSON.parse(event.data));
  };
  // 连接打开时触发
  eventSource.onopen = function (event) {
    console.log("Connection opened");
    props.onSSEStart?.(event);
    handleCancel();
  };
  // 连接关闭或出现错误时触发
  eventSource.onerror = function (event) {
    if (event.eventPhase === EventSource.CLOSED) {
      console.log("Connection closed");
      eventSource.close();
      props.onSSEEnd?.(event);
    }

    SSEsubmitting.value = false;
  };
};
</script>

<style scoped></style>
